






























































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ComponentType, ViewItem, EnvironmentUser } from '@/models'
import * as ComponentTypes from '@/components/componentTypes'
import Fields from '@/components/form/Fields.vue'

@Component({
  components: {
    Fields,
  },
})
export default class ComponentEditorDialog extends Vue {
  /** Dialog open value */
  @Prop({ type: Boolean, default: true }) value!: boolean
  /** Current Environment ID */
  @Prop({ type: String, required: true }) environmentId!: string
  /** Current Environment User (Front-end management only) */
  @Prop({ type: Object, required: false }) environmentUser?: EnvironmentUser
  /** Current component type name */
  @Prop({ type: String, required: true }) componentTypeName!: string
  /** Current component ID */
  @Prop({ type: String, required: true }) componentId!: String
  /** View Item */
  @Prop({ type: Object }) viewItem?: ViewItem
  /** Component Name */
  displayName = ''
  /** Dialog width */
  @Prop({ type: Number, default: 1280 }) width!: number

  currentTab = 'component'

  additionalTabs: Record<string, any> = {}

  get hasAdditionalTabs() {
    return Object.keys(this.additionalTabs).length
  }

  @Watch('componentTypeName')
  clearAdditionalTabs(cType: string, prevCtype: string) {
    if (cType !== prevCtype) {
      this.$set(this, 'additionalTabs', {})
      this.currentTab = 'component'
    }
  }

  updateAdditionalTabs(additionalTabs: Record<string, string>) {
    this.$set(this, 'additionalTabs', additionalTabs)
  }

  get actualTab() {
    if (['component', 'viewItem'].includes(this.currentTab)) {
      return this.currentTab
    } else {
      return 'component'
    }
  }

  get open() {
    return this.value
  }

  set open(v) {
    this.$emit('input', v)
  }

  get viewItemSync() {
    return this.viewItem
  }

  set viewItemSync(v) {
    this.$emit('update:viewItem', v)
  }

  updateName(name: string) {
    this.displayName = name
  }

  handleDelete() {
    this.$emit('delete', this.componentId)
    this.$emit('input', false)
    return this.$store.dispatch('snackbar/showSnackbar', {
      text: `${this.componentType.titleSingle} ${this.$t(
        'editDialog.script.handleDelete.text'
      )}`,
      color: '',
    })
  }

  handleSave() {
    this.$emit('save', this.componentId)
    this.$emit('input', false)
    return this.$store.dispatch('snackbar/showSnackbar', {
      text: `${this.componentType.titleSingle} ${this.$t(
        'editDialog.script.handleSave.text'
      )}`,
      color: '',
    })
  }

  handleError(e: any) {
    return this.$store.dispatch('snackbar/showSnackbar', {
      text: this.$t('editDialog.script.handleError.text') + e.message,
      color: 'error',
    })
  }

  /** Current component type */
  get componentType(): ComponentType<any> {
    return (ComponentTypes as Record<string, ComponentType<any>>)[
      this.componentTypeName
    ]
  }
}
